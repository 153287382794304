import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Context from '~/context/DataContext';

const DataProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query StaticQuery {
      rawShopify {
        productTranslations: translatableResources(
          resourceType: PRODUCT
          first: 200
        ) {
          nodes {
            resourceId
            translations(locale: "en") {
              key
              locale
              value
            }
          }
        }
        collectionTranslations: translatableResources(
          resourceType: COLLECTION
          first: 30
        ) {
          nodes {
            resourceId
            translations(locale: "en") {
              locale
              value
              key
            }
          }
        }
      }
      collections: allShopifyCollection(sort: { title: ASC }) {
        nodes {
          handle
          title
          shopifyId
        }
      }
    }
  `);

  return (
    <Context.Provider
      value={{
        translations: {
          products: data.rawShopify.productTranslations.nodes,
          collections: data.rawShopify.collectionTranslations.nodes,
        },
        collectionLinks: data.collections.nodes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DataProvider;
