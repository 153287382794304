exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contentful-page-slug-jsx": () => import("./../../../src/pages/{ContentfulPage.slug}.jsx" /* webpackChunkName: "component---src-pages-contentful-page-slug-jsx" */),
  "component---src-pages-erbjudanden-jsx": () => import("./../../../src/pages/erbjudanden.jsx" /* webpackChunkName: "component---src-pages-erbjudanden-jsx" */),
  "component---src-pages-formgivare-index-jsx": () => import("./../../../src/pages/formgivare/index.jsx" /* webpackChunkName: "component---src-pages-formgivare-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nyheter-contentful-article-slug-jsx": () => import("./../../../src/pages/nyheter/{ContentfulArticle.slug}.jsx" /* webpackChunkName: "component---src-pages-nyheter-contentful-article-slug-jsx" */),
  "component---src-pages-nyheter-index-jsx": () => import("./../../../src/pages/nyheter/index.jsx" /* webpackChunkName: "component---src-pages-nyheter-index-jsx" */),
  "component---src-pages-produkter-index-jsx": () => import("./../../../src/pages/produkter/index.jsx" /* webpackChunkName: "component---src-pages-produkter-index-jsx" */),
  "component---src-pages-samarbeten-contentful-collaboration-slug-jsx": () => import("./../../../src/pages/samarbeten/{ContentfulCollaboration.slug}.jsx" /* webpackChunkName: "component---src-pages-samarbeten-contentful-collaboration-slug-jsx" */),
  "component---src-pages-samarbeten-index-jsx": () => import("./../../../src/pages/samarbeten/index.jsx" /* webpackChunkName: "component---src-pages-samarbeten-index-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-designer-jsx": () => import("./../../../src/templates/designer.jsx" /* webpackChunkName: "component---src-templates-designer-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

