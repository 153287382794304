import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { ParallaxProvider } from 'react-scroll-parallax';

import { GlobalStyle, theme } from '~/utils/styles';

import ConsentProvider from '~/provider/ConsentProvider';
import ContextProvider from '~/provider/ContextProvider';
import DataProvider from '~/provider/DataProvider';

const Layouts = ({ children }) => {
  return (
    <DataProvider>
      <ConsentProvider>
        <ContextProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ParallaxProvider>{children}</ParallaxProvider>
          </ThemeProvider>
        </ContextProvider>
      </ConsentProvider>
    </DataProvider>
  );
};

export default Layouts;
